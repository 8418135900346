















































import { defineComponent, reactive, ref, toRaw } from '@vue/composition-api';
import { useToast } from 'vue-toastification/composition';
import { useApi } from '@/api';
import { useStore } from '@/store';
import { ProfileUpdateBody } from '@project/shared';
import RequireOnline from '@/components/RequireOnline.vue';

export default defineComponent({
  components: {
    RequireOnline,
  },

  setup(props, { emit }) {
    const toast = useToast();
    const api = useApi();
    const loading = ref(false);
    const store = useStore();

    const formRef = ref<{
      validate(): void;
    } | null>(null);

    const values = reactive<ProfileUpdateBody>({
      email: '',
      oldPassword: '',
      newPassword: '',
    });

    api.auth.me().then((res) => {
      for (const key in values) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        values[key] = res[key];
      }
    });

    const rules = {
      email: [(v: string) => !!v || 'Email is required'],
    };

    const validForm = ref(false);

    async function onSubmit() {
      if (validForm.value) {
        loading.value = true;
        try {
          store.state.user = await api.auth.updateMe(values);
          emit('input', toRaw(values));
          toast.success('Your pofile is updated');
          values.oldPassword = values.newPassword = '';
        } catch (err) {
          if (err.response && err.response.status === 403) {
            toast.error(`Bad password`);
            return;
          }
          throw err;
        } finally {
          loading.value = false;
        }
      } else {
        toast.warning('Some fields are empty or incorrect values');
      }
    }

    return {
      values,
      validForm,
      rules,
      formRef,
      onSubmit,
      loading,
    };
  },
});
